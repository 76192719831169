import { decodeJWT } from "aws-amplify/auth";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import * as AWS from "aws-sdk";
import {
  GetUserRequest,
  InitiateAuthResponse,
} from "aws-sdk/clients/cognitoidentityserviceprovider";
import { PromiseResult } from "aws-sdk/lib/request";
import * as CryptoJS from "crypto-js";

interface ExtendInitiateAuthResponse extends InitiateAuthResponse {
  email?: string;
  name?: string;
  role?: string;
  companyName?: string;
  cognitoUserId?: string;
  userGroups?: string | undefined;
}
export class CognitoManager {
  private cognitoIdentityServiceProvider: CognitoIdentityServiceProvider;
  private clientId: string | undefined;
  private clientSecretId: string | undefined;
  private regionId: string | undefined;

  constructor(regionId: string, clientId: string, secretId: string) {
    this.regionId = regionId;
    AWS.config.update({
      region: this.regionId,
    });
    this.cognitoIdentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();
    this.clientId = clientId;
    this.clientSecretId = secretId;
    this.cognitoIdentityServiceProvider.config.region = this.regionId;
  }

  /**
   * Confirms the sign-up of a user with the provided username and confirmation code.
   * @param username The username (usually an email address) of the user.
   * @param code The confirmation code sent to the user's email or phone number.
   * @returns A Promise resolving to the confirmation data if successful, otherwise an error.
   * @throws Error if the sign-up confirmation process fails.
   */
  public async signUpUser(username: string, password: string): Promise<any> {
    try {
      // Calculate the secret hash
      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          username + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }

      // Set up parameters for the confirmSignUp request
      const params: CognitoIdentityServiceProvider.SignUpRequest = {
        ClientId: this.clientId!,
        Username: username,
        Password: password,
        SecretHash: secretHash,
      };
      // Call the confirmSignUp method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .signUp(params)
        .promise();


      // After confirming, log in the user to get tokens
      const authParams = {
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: this.clientId as string,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
          SECRET_HASH: secretHash,
        },
      };

      const authResult = await this.cognitoIdentityServiceProvider
        .initiateAuth(authParams)
        .promise();

      if (authResult && authResult.AuthenticationResult) {
        const { AccessToken, RefreshToken, IdToken } =
          authResult.AuthenticationResult;
        if (IdToken && AccessToken && RefreshToken) {
          const encodedToken = IdToken.split(".")[1];
          const decodedToken = atob(encodedToken);
          const customAttributes = JSON.parse(decodedToken);
          // You can now use these tokens as needed
          return {
            AccessToken,
            RefreshToken,
            IdToken,
            cognitoUserId: customAttributes["cognito:username"],
          };
        }
        // Access custom attributes
      }
      return data;
    } catch (error: any) {
      throw new Error(`[signUpUser]::${error.message}`);
    }
  }

  /**
   * Signs in a user using their username and password.
   * @param username The username (usually an email address) of the user.
   * @param password The password of the user.
   * @returns A Promise resolving to the authentication data if successful, otherwise an error.
   * @throws Error if the sign-in process fails.
   */
  public async signInUser(
    username: string,
    password: string,
    authflow: string
  ): Promise<any> {
    try {
      // Calculate the secret hash

      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          username + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }

      // Set up parameters for the initiateAuth request
      const params: CognitoIdentityServiceProvider.InitiateAuthRequest = {
        AuthFlow: authflow,
        ClientId: this.clientId as string,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
          SECRET_HASH: secretHash,
        },
      };
      // Call the initiateAuth method and wait for the result
      const data: PromiseResult<InitiateAuthResponse, AWS.AWSError> =
        await this.cognitoIdentityServiceProvider
          .initiateAuth(params)
          .promise();

      // console.log("congnitodata", data);
      const result: ExtendInitiateAuthResponse = { ...data };

      // Check if custom attributes are present in the response
      if (data.AuthenticationResult && data.AuthenticationResult.IdToken) {
        // Decode the JWT token to get the custom attributes
        const idToken = data.AuthenticationResult.IdToken;
        const payload = decodeJWT(idToken)?.payload;
        const userGroups: any = payload["cognito:groups"];
        const encodedToken = idToken.split(".")[1];
        const decodedToken = atob(encodedToken);
        const customAttributes = JSON.parse(decodedToken);
        // Access custom attributes
        result.cognitoUserId = customAttributes["cognito:username"];
        result.email = customAttributes.email;
        result.userGroups = userGroups ? userGroups[0] : "";
      }
      return result;
    } catch (error: any) {
      throw new Error(`[signInUser]::${error.message}`);
    }
  }

  public async getUserGroups(accessToken: string) {
    const params: GetUserRequest = {
      AccessToken: accessToken,
    };

    try {
      const userResponse = await this.cognitoIdentityServiceProvider
        .getUser(params)
        .promise();
      const userAttributes = userResponse.UserAttributes;

      // Extract user group information (User groups are stored in the 'cognito:groups' attribute)
      const groupsAttr = userAttributes.find(
        (attr: { Name: string }) => attr.Name === "cognito:groups"
      );

      if (groupsAttr) {
        return groupsAttr.Value?.split(",") || []; // Return groups as an array
      }

      return [];
    } catch (error) {
      console.error("Error getting user groups", error);
      throw error;
    }
  }

  public async changePassword(
    accessToken: string,
    previousPassword: string,
    proposedPassword: string
  ): Promise<CognitoIdentityServiceProvider.ChangePasswordResponse> {
    try {
      const params = {
        AccessToken: accessToken,
        PreviousPassword: previousPassword,
        ProposedPassword: proposedPassword,
      };
      const result = await this.cognitoIdentityServiceProvider
        .changePassword(params)
        .promise();
      console.log("Password changed successfully");
      return result;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  }
  /**
   * Sends a verification code to the specified email address for user sign-up.
   * @param email The email address of the user.
   * @param password The password for the user.
   * @returns A Promise resolving to the data returned by the AWS Cognito service if successful, otherwise an error.
   */
  public async sendVerficationCode(
    email: string,
    password: string
    // role:string
  ): Promise<CognitoIdentityServiceProvider.SignUpResponse> {
    try {
      // Calculate the secret hash

      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          email + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }

      // Set up parameters for the sign-up request
      const signUpParams: CognitoIdentityServiceProvider.SignUpRequest = {
        ClientId: this.clientId as string,
        Username: email,
        Password: password,
        SecretHash: secretHash,
        UserAttributes: [
          {
            Name: "email",
            Value: email,
          },
        ],
      };
      // Call the signUp method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .signUp(signUpParams)
        .promise();
      console.log("Verification code sent successfully:", data);
      return data;
    } catch (error: any) {
      console.log("sendVerfications", error);
      throw new Error(`[sendVerficationCode]::${error.message}`);
    }
  }

  /**
   * Resends the verification code to the specified username in Amazon Cognito User Pools.
   *
   * @param username - The username for which to resend the verification code.
   * @returns A promise that resolves with the result of the resend operation.
   * @throws If an error occurs while resending the verification code.
   */
  public async resendVerificationCode(
    username: string
  ): Promise<CognitoIdentityServiceProvider.ResendConfirmationCodeResponse> {
    try {
      // Calculate the secret hash

      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          username + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }

      // Set up parameters for the resendVerificationCode request
      const resendParams: CognitoIdentityServiceProvider.ResendConfirmationCodeRequest =
        {
          ClientId: this.clientId as string,
          Username: username,
          SecretHash: secretHash,
        };
      // Call the resendConfirmationCode method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .resendConfirmationCode(resendParams)
        .promise();
      console.log("Resend Verification code sent successfully:", data);
      return data;
    } catch (error: any) {
      throw new Error(`[resendVerificationCode]::${error.message}`);
    }
  }

  /**
   * Initiates a password reset request for the specified username in Amazon Cognito User Pools.
   *
   * @param username - The username for which to initiate the password reset request.
   * @returns A promise that resolves with the result of the password reset initiation.
   * @throws If an error occurs while initiating the password reset request.
   */
  public async initiatePasswordReset(
    username: string
  ): Promise<CognitoIdentityServiceProvider.ForgotPasswordResponse> {
    try {
     
      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          username + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }

      // Set up parameters for the initiatePasswordReset request
      const initiatePasswordParams: CognitoIdentityServiceProvider.ForgotPasswordRequest =
        {
          ClientId: this.clientId as string,
          Username: username,
          SecretHash: secretHash,
        };
      // Call the forgotPassword method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .forgotPassword(initiatePasswordParams)
        .promise();
      console.log("Initiate Password Reset code sent successfully:", data);
      return data;
    } catch (error: any) {
      throw new Error(`[initiatePasswordReset]::${error.message}`);
    }
  }



  /**
   * Confirms a password reset request with the provided confirmation code and sets a new password for the specified username in Amazon Cognito User Pools.
   *
   * @param username - The username for which to confirm the password reset request.
   * @param code - The confirmation code sent to the user's email or phone number.
   * @param newPassword - The new password to set for the user.
   * @returns A promise that resolves with the result of the password reset confirmation.
   * @throws If an error occurs while confirming the password reset request.
   */
  public async confirmPasswordReset(
    username: string,
    code: string,
    newPassword: string
  ): Promise<CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse> {
    try {
      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          username + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }
      // Set up parameters for the initiatePasswordReset request
      const conformPasswordParams: CognitoIdentityServiceProvider.ConfirmForgotPasswordRequest =
        {
          ClientId: this.clientId as string,
          Username: username,
          ConfirmationCode: code,
          SecretHash: secretHash,
          Password: newPassword,
        };
      // Call the forgotPassword method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .confirmForgotPassword(conformPasswordParams)
        .promise();
      console.log("Password reset confirmed successfully.");
      return data;
    } catch (error: any) {
      throw new Error(`[confirmPasswordReset]::${error.message}`);
    }
  }

  /**
   * Logs out a user by invalidating the provided access token using AWS Cognito's global sign-out feature.
   *
   * @param accessToken The access token of the user to be logged out.
   * @returns A promise that resolves with the result of the logout operation.
   * @throws An error if there is an issue with the logout operation.
   */
  public async logoutUser(
    accessToken: string
  ): Promise<CognitoIdentityServiceProvider.GlobalSignOutResponse> {
    try {
      // Set up parameters for the logoutUser request
      const globalSignOutRequestParams: CognitoIdentityServiceProvider.GlobalSignOutRequest =
        {
          AccessToken: accessToken,
        };
      // Call the logout method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .globalSignOut(globalSignOutRequestParams)
        .promise();
      console.log("User Logout Successfully.", data);
      return data;
    } catch (error: any) {
      throw new Error(`[logoutUser]::${error.message}`);
    }
  }

  /**
   * Verifies the validity of an access token by attempting to retrieve user information from AWS Cognito.
   *
   * @param accessToken The access token to be verified.
   * @returns A promise that resolves with a boolean indicating whether the token is valid (true) or not (false).
   * @throws An error if there is an issue with the verification process.
   */
  public async verifyToken(
    accessToken: string
  ): Promise<CognitoIdentityServiceProvider.GetUserResponse> {
    try {
      const verfiyTokenparams: CognitoIdentityServiceProvider.GetUserRequest = {
        AccessToken: accessToken,
      };
      const result = await this.cognitoIdentityServiceProvider
        .getUser(verfiyTokenparams)
        .promise();
      console.log("Token is valid");
      return result;
    } catch (error: any) {
      throw new Error(`[verifyToken]::${error.message}`);
    }
  }

  /**
   * Signs in a user using their username and password.
   * @param username The username (usually an email address) of the user.
   * @param password The password of the user.
   * @returns A Promise resolving to the authentication data if successful, otherwise an error.
   * @throws Error if the sign-in process fails.
   */
  public async refreshAccessTokenAndIsRefreshTokenExpired(
    refreshToken: string,
    username: string
  ) {
    try {
      let secretHash = "";
      if (this.clientSecretId) {
        secretHash = CryptoJS.HmacSHA256(
          username + this.clientId,
          this.clientSecretId
        ).toString(CryptoJS.enc.Base64);
      }

      // Set up parameters for the refresh Access token
      const params: any = {
        AuthFlow: "REFRESH_TOKEN_AUTH",
        ClientId: this.clientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
          SECRET_HASH: secretHash,
        },
      };

      // Call the initiateAuth method and wait for the result
      const data = await this.cognitoIdentityServiceProvider
        .initiateAuth(params)
        .promise();
      return data;
    } catch (error: any) {
      if (
        error.code === "NotAuthorizedException" &&
        error.message === "Refresh Token has expired"
      ) {
        console.error("Refresh token has expired");
        // Handle expired refresh token
        return error;
      } else {
        throw new Error(`[refreshAccessToken]::${error.message}`);
      }
    }
  }
}
