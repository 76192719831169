import {
  // CalendarMonth,
  CallEnd,
  // Circle,
  // Edit,
  Mic,
  MicOff,
  // Pause,
  // PlayArrow,
  // Save,
  Videocam,
  VideocamOff,
} from "@mui/icons-material";
import AWS from "aws-sdk";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import { useAuth } from "../../customHooks/AuthContext";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AppDrawer from "../../Layout/AppDrawer";
import { formatTime } from "../../Utils/utils";
import CustomModal from "../CustomComponents/CustomModal";
import { LoadingButton } from "@mui/lab";
import { commonBtnStyle } from "../../style";
import { patchData, postData } from "../../API/apiService";

const NewSessionRecord = () => {
  const location = useLocation();
  const { tokenDetails } = useAuth();
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const navigate = useNavigate();
  const { sessionid, patientid, patientDetails, chunkingTiming } =
    location.state || {};
  const practionerId = practionerDetails?.practionerInfo?.id;
  const [seconds, setSeconds] = useState<number>(0);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isCameraOn] = useState(false);
  const [isPauseOn] = useState(false);
  const [showSessionEndPrompt, setShowSessionEndPrompt] = useState(false);
  const [sessionBtnLoading, setSessionBtnLoading] = useState(false);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const chunkCountRef = useRef(1);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  AWS.config.region = process.env.REACT_APP_AWS_REGION_ID!;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID!,
    Logins: {
      [process.env.REACT_APP_AWS_USER_POOL_ID!]: tokenDetails.IdToken,
    },
  });

  const s3 = new AWS.S3();

  const handleMicClick = () => {
    setIsMicOn((prev) => !prev);
    if (isMicOn) {
      mediaRecorderRef.current?.stream
        .getTracks()
        .forEach((track) => (track.enabled = false));
    } else {
      mediaRecorderRef.current?.stream
        .getTracks()
        .forEach((track) => (track.enabled = true));
    }
  };

  // const handlePauseClick = () => {
  //   setIsPauseOn((prev) => !prev); // Toggle pause/play status
  //   if (!isPauseOn) {
  //     if (
  //       mediaRecorderRef.current &&
  //       mediaRecorderRef.current.state === "recording"
  //     ) {
  //       mediaRecorderRef.current.pause();
  //     }
  //   } else {
  //     if (
  //       mediaRecorderRef.current &&
  //       mediaRecorderRef.current.state === "paused"
  //     ) {
  //       mediaRecorderRef.current.resume();
  //     }
  //   }
  // };

  useEffect(() => {
    startRecording();
    let interval: string | number | NodeJS.Timeout | undefined;

    if (!isPauseOn) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPauseOn]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.start();

      mediaRecorder.onstop = async () => {
        if (!isPauseOn) {
          // Only upload if not paused
          const tempBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          await uploadAudioChunk(tempBlob);
          audioChunks.current = []; // Clear the chunks after uploading
        }
      };

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      // Set up interval to stop and restart recording every 10 seconds
      recordingIntervalRef.current = setInterval(() => {
        if (
          mediaRecorderRef.current &&
          mediaRecorderRef.current.state !== "inactive"
        ) {
          mediaRecorderRef.current.stop();
          mediaRecorderRef.current.start();
        }
      }, chunkingTiming); // 10 seconds
    });
  };

  // const uploadAudioChunk = async (blob: Blob) => {
  //   if (blob.size > 0) {
  //     const params = {
  //       Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
  //       Key: `${practionerId}/${patientid}/${sessionid}/audio/part_${chunkCountRef.current}.wav`,
  //       Body: blob,
  //       ContentType: "audio/wav",
  //     };

  //     s3.upload(params, async (err: any, data: any) => {
  //       if (err) {
  //         console.error("Error uploading audio:", err);
  //       } else {
  //         await uploadAudioChunkSessionAPI(data);
  //         console.log("Successfully uploaded audio:", data);
  //         chunkCountRef.current += 1;
  //       }
  //     });
  //   } else {
  //     console.warn("Attempted to upload an empty blob.");
  //   }
  // };


  const uploadAudioChunk = async (blob: Blob) => {
    if (blob.size > 0) {
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
        Key: `${practionerId}/${patientid}/${sessionid}/audio/part_${chunkCountRef.current}_overlap.wav`,
        Body: blob,
        ContentType: "audio/wav",
      };
 
      s3.upload(params, async (err: any, data: any) => {
        if (err) {
          console.error("Error uploading audio:", err);
        } else {
          await uploadAudioChunkSessionAPI(data);
          console.log("Successfully uploaded audio:", data);
          chunkCountRef.current += 1;
        }
      });
    } else {
      console.warn("Attempted to upload an empty blob.");
    }
  };

  
  const uploadAudioChunkSessionAPI = async (data: any) => {
    try {
      const payload = {
        practitioner_id: practionerId,
        session_id: sessionid,
        sequence: chunkCountRef.current,
        chunked_audio_url: data.Location,
        transcribe_output_url: "",
        patient_id: patientid,
        created_by: practionerId,
        modified_by: practionerId,
      };
      const APIResponse = await postData("/audio", payload);
      if (APIResponse) {
        console.log("Successfully uploaded audioSession in API:", APIResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSessionEnd = async () => {
    try {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
        setSessionBtnLoading(true);
        await patchData(`/sessionEnd?id=${sessionid}`, {
          number_of_chunks: chunkCountRef.current,
          created_by: practionerDetails.practionerInfo.id,
          modified_by: practionerDetails.practionerInfo.id,
        });
        setShowSessionEndPrompt(false);
        navigate("/session-report", {
          state: {
            patientDetails: patientDetails,
            sessionid: sessionid,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppDrawer>
      <ToastContainer />
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Paper
          variant="outlined"
          sx={{
            padding: "08px",
            background: "#fafafa",
            borderRadius: "10px", // Adjust the border radius as needed
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px", // Adjust the gap between icon and text as needed
          }}
        >
          <Mic sx={{ color: "red", fontSize: "20px" }} />
          <Typography>{formatTime(seconds)}</Typography>
          <Typography>
            {isPauseOn ? "Recording Paused" : "Recording in Progress"}
          </Typography>
        </Paper>
      </Grid>

      <Box height={50}></Box>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Avatar
            sizes="small"
            sx={{
              height: "155px",
              width: "155px",
              // alignItems: "center",
              // backgroundColor: "#233f42",
              // fontSize: "72px",
              // border: "10px solid #efdf99",
            }}
          >
            <img src="/assets/images/Doctorpic2.png" alt="Logo" />
          </Avatar>
        </Grid>
      </Grid>
      <Box height={10}></Box>

      {!isPauseOn && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img
              src="/assets/images/sound-8563.gif"
              alt="Logo"
              style={{
                height: "30vh",
                width: "60vh", // Adjust width as needed
              }}
            />
          </Grid>
        </Grid>
      )}
      {isPauseOn && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img
              src="/assets/images/recording.jpg"
              alt="Logo"
              style={{
                height: "30vh",
                width: "60vh", //
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={10}
      >
        <Grid item>
          <Tooltip
            title={isMicOn ? "Turn off microphone" : "Turn on microphone"}
          >
            <IconButton
              sx={{
                color: "black",
                background: isMicOn ? "#ffcc00" : "#f0f1f5",
              }}
              onClick={handleMicClick} // Handle click event
            >
              {isMicOn ? <Mic /> : <MicOff />}{" "}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={isCameraOn ? "Turn off camera" : "Turn on camera"}>
            <IconButton
              sx={{
                color: "black",
                background: isCameraOn ? "#ffcc00" : "#f0f1f5",
              }}
            >
              {isCameraOn ? <Videocam /> : <VideocamOff />}{" "}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            className="btn-delete"
            size="small"
            variant="contained"
            color="error"
            // onClick={stopRecording}
            onClick={() => setShowSessionEndPrompt(true)}
          >
            <CallEnd />
          </Button>
        </Grid>
        {/* <Grid item>
          <Tooltip title={isPauseOn ? "Turn on play" : "Turn on pause"}>
            <IconButton
              sx={{
                color: "black",
                background: isPauseOn ? "#ffcc00" : "#f0f1f5",
              }}
              onClick={handlePauseClick} // Handle click event
            >
              {isPauseOn ? <PlayArrow /> : <Pause />}{" "}
            </IconButton>
          </Tooltip>
        </Grid> */}
      </Grid>

      {/* SessionEnd Modal */}
      <CustomModal
        open={showSessionEndPrompt}
        handleClose={() => setShowSessionEndPrompt(false)}
        size="small"
      >
        <Box
          sx={{
            borderLeft: "5px solid #ffcc00",
          }}
        >
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <img
                  src="/assets/images/Doctorpic2.png"
                  alt="Logo"
                  style={{
                    height: "15vh",
                    width: "20vh", // Adjust width as needed
                  }}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>Clicking on Submit Will end session</Grid>
                  <Grid item>
                    <DialogActions>
                      <Button
                        onClick={() => setShowSessionEndPrompt(false)}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        sx={{ color: "black", textTransform: "none" }}
                      >
                        Decline
                      </Button>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={handleSessionEnd}
                        autoFocus
                        size="small"
                        loading={sessionBtnLoading}
                        sx={commonBtnStyle}
                      >
                        Accept
                      </LoadingButton>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </CustomModal>
    </AppDrawer>
  );
};

export default NewSessionRecord;
