import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { CognitoManager } from "../clients/CognitoManager";
import axiosInstance from "../API/axiosInstance"; // Custom Axios instance
import { getData, postData } from "../API/apiService";

interface AuthState {
  role: string;
  isAuthenticated: boolean;
  userDetails: any;
  tokenDetails: any;
  adminPractitionerId: number;
}

interface practitionerDataType {
  first_name: string;
  last_name: string;
  address: string;
  speciality: string;
}

interface AuthContextProps extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  register: (formData: any) => Promise<void>;
  logout: () => void;
  sendVerficationCode: (email: string, password: string) => Promise<any>;
  resendVerficationCode: (email: string) => Promise<any>;
  initiatePasswordReset: (email: string) => Promise<any>;
  confirmPasswordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  changePassword: (
    accessToken: string,
    previousPassword: string,
    proposedPassword: string
  ) => Promise<void>;
  updatePractitioner: (practitionerData: practitionerDataType) => Promise<void>;

  updateAdminPractitionerId:(id:number)=>Promise<void>;
  
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface

const initialState: AuthState = {
  isAuthenticated: true,
  userDetails: null,
  tokenDetails: null,
  role: "",
  adminPractitionerId: 0,
};

const defaultContextValues: AuthContextProps = {
  ...initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  register: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sendVerficationCode: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resendVerficationCode: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initiatePasswordReset: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  confirmPasswordReset: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePassword: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePractitioner: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateAdminPractitionerId: async () => {},
};

const setSession = (tokenDetails: any, user: any, role: string) => {
  if (tokenDetails) {
    localStorage.setItem("accessToken", tokenDetails?.AccessToken);
    localStorage.setItem("refreshToken", tokenDetails?.RefreshToken);
    localStorage.setItem("IdToken", tokenDetails?.IdToken);
    localStorage.setItem("role", role);
    localStorage.setItem("userData", JSON.stringify(user));
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokenDetails.AccessToken}`;
  } else {
    clearSession();
  }
};

const clearSession = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userData");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("IdToken");
  delete axiosInstance.defaults.headers.common.Authorization;
};

const AuthContext = createContext<AuthContextProps>(defaultContextValues);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const regionId = process.env["REACT_APP_AWS_REGION_ID"];
  const clientId = process.env["REACT_APP_AWS_CONGNITO_CLIENT_ID"];
  const secretId = process.env["REACT_APP_AWS_CONGNITO_SECRET_ID"];
  let cognitoAuth: CognitoManager;
  if (regionId && clientId && secretId) {
    cognitoAuth = new CognitoManager(regionId, clientId, secretId);
  }
  const [authState, setAuthState] = useState<AuthState>(initialState);

  const login = async (email: string, password: string) => {
    try {
      const signInResult = await cognitoAuth.signInUser(
        email,
        password,
        "USER_PASSWORD_AUTH"
      );
      if (signInResult) {
        const role =
          signInResult.userGroups === "aida-admin-group"
            ? "admin"
            : "practitioner";
        setAuthState({
          ...authState,
          isAuthenticated: true,
          userDetails: { email, practionerInfo: {} },
          tokenDetails: signInResult.AuthenticationResult,
          role,
        });
        setSession(
          signInResult.AuthenticationResult,
          {
            email,
            practionerInfo: {},
          },
          role
        );
        const practionerInfo = await getData("/practitionerViaCognito");
        signInResult.practionerInfo = practionerInfo;
        setAuthState({
          ...authState,
          isAuthenticated: true,
          userDetails: { email, practionerInfo },
          tokenDetails: signInResult.AuthenticationResult,
          role,
        });
        setSession(
          signInResult.AuthenticationResult,
          {
            email,
            practionerInfo,
          },
          role
        );

        return signInResult;
      } else {
        return signInResult;
      }
    } catch (error: any) {
      console.error("Error signing in:", error);
      return error;
    }
  };

  const register = async (formData: any) => {
    try {
      const signUpResult: any = await cognitoAuth.signUpUser(
        formData.email,
        formData.password
      );
      const { AccessToken, RefreshToken, cognitoUserId, IdToken } =
        signUpResult;
      if (signUpResult) {
        const role = "practitioner";
        setAuthState({
          ...authState,
          isAuthenticated: true,
          userDetails: {
            email: formData.email,
            practitionerInfo: {},
          },
          tokenDetails: { AccessToken, RefreshToken, IdToken },
          role,
        });
        setSession(
          { AccessToken, RefreshToken, IdToken },
          { email: formData.email, practionerInfo: {} },
          role
        );
        await createPractitioner(formData, cognitoUserId);

        const practitionerInfo = await getPractitionerDetails();
        setAuthState({
          ...authState,
          isAuthenticated: true,
          userDetails: {
            email: formData.email,
            practitionerInfo: practitionerInfo,
          },
          tokenDetails: { AccessToken, RefreshToken, IdToken },
        });
        setSession(
          { AccessToken, RefreshToken, IdToken },
          { email: formData.email, practionerInfo: practitionerInfo },
          role
        );
        return signUpResult;
      } else {
        return signUpResult;
      }
    } catch (error: any) {
      console.error("Error singup in:", error);
      return error;
    }
  };

  const sendVerficationCode = async (email: string, password: string) => {
    try {
      const sendVerficationResult = await cognitoAuth.sendVerficationCode(
        email,
        password
      );
      return sendVerficationResult;
    } catch (error) {
      console.error("Error sendVerficationCode:", error);
      return error;
    }
  };

  const logout = async (): Promise<void> => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken) {
        await cognitoAuth.logoutUser(accessToken);
        clearSession();
        setAuthState({
          ...authState,
          isAuthenticated: false,
          userDetails: null,
        });
        window.location.href = "/signin";
      }
    } catch (error) {
      clearSession();
      console.log("Logout Error", error);
      setAuthState({ ...authState, isAuthenticated: false, userDetails: null });
      window.location.href = "/signin";
    }
  };

  const resendVerficationCode = async (email: string) => {
    try {
      const resendVerficationCodeResult =
        await cognitoAuth.resendVerificationCode(email);
      return resendVerficationCodeResult;
    } catch (error) {
      console.error("Error resendVerficationCode:", error);
      return error;
    }
  };

  const initiatePasswordReset = async (email: string) => {
    try {
      const resendVerficationCodeResult =
        await cognitoAuth.initiatePasswordReset(email);
      return resendVerficationCodeResult;
    } catch (error) {
      console.error("Error resendVerficationCode:", error);
      return error;
    }
  };

  const confirmPasswordReset = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    try {
      const resendVerficationCodeResult: any =
        await cognitoAuth.confirmPasswordReset(username, code, newPassword);
      return resendVerficationCodeResult;
    } catch (error) {
      console.error("Error resendVerficationCode:", error);
      return error;
    }
  };

  const getPractitionerDetails = async () => {
    const APIResponse = await getData("/practitionerViaCognito");
    return APIResponse;
  };

  const createPractitioner = async (formData: any, cognitoUserId: string) => {
    try {
      const payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        userid: cognitoUserId,
        address: formData.address,
      };
      const APIResponse = await postData("/practitioner", payload);
      if (APIResponse) {
        return APIResponse;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const changePassword = async (
    accessToken: string,
    previousPassword: string,
    proposedPassword: string
  ) => {
    try {
      const changePasswordResult: any = await cognitoAuth.changePassword(
        accessToken,
        previousPassword,
        proposedPassword
      );
      return changePasswordResult;
    } catch (error) {
      console.error("Error changePassword:", error);
      return error;
    }
  };

  const updatePractitioner = async (practitionerData: practitionerDataType) => {
    setAuthState((prevState) => ({
      ...prevState, // Spread the entire previous state
      userDetails: {
        ...prevState.userDetails, // Spread the previous `userDetails` object
        practionerInfo: {
          ...prevState.userDetails?.practionerInfo, // Spread the previous `practitionerInfo` object if it exists
          ...practitionerData, // Merge with the updated practitioner data
        },
      },
    }));

    const userDetailsFromStorage = JSON.parse(
      localStorage.getItem("userDetails") || "{}"
    );
    const updatedUserDetails = {
      ...userDetailsFromStorage,
      practitionerInfo: {
        ...userDetailsFromStorage?.practitionerInfo,
        ...practitionerData, // Merge the updated practitioner data
      },
    };
    localStorage.setItem("userDetails", JSON.stringify(updatedUserDetails));
  };

  const updateAdminPractitionerId = async (id: number) => {
    setAuthState((prevState) => ({
      ...prevState, // Spread the entire previous state
      adminPractitionerId: id,
    }));
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const refreshToken = window.localStorage.getItem("refreshToken");
        const userData = window.localStorage.getItem("userData");
        const role = window.localStorage.getItem("role");
        const IdToken = window.localStorage.getItem("IdToken");

        if (userData && role) {
          setAuthState({
            ...authState,
            role,
            isAuthenticated: true,
            userDetails: JSON.parse(userData),
            tokenDetails: {
              AccessToken: accessToken,
              RefreshToken: refreshToken,
              IdToken: IdToken,
            },
          });
        } else {
          setAuthState({
            ...authState,
            isAuthenticated: false,
            userDetails: null,
          });
          clearSession();
        }
      } catch (err) {
        console.error(err);
        setAuthState({
          ...authState,
          isAuthenticated: false,
          userDetails: null,
        });
        clearSession();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        register,
        logout,
        sendVerficationCode,
        resendVerficationCode,
        initiatePasswordReset,
        confirmPasswordReset,
        changePassword,
        updatePractitioner,
        updateAdminPractitionerId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
