import axios from "axios";

// Create an Axios instance - KS
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MYAIDA_SERVERLESS_API_HOST, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to inject token into headers before each request
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken"); // Fetch token from storage or other source
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      console.log("Error status code:", error.response.status); // Access the status code
      console.log("Error data:", error.response.data); // Optionally log the error data
      return Promise.reject({
        status: error.response.status,
        data: error.response.data,
      });
    } else if (error.request) {
      // The request was made but no response was received
      console.log("No response received:", error.request);
      logout();
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error message:", error.message);
      return Promise.reject({
        status: null,
        message: error.message,
      });
    }
  }
);

const logout = async () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userData");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("IdToken");
  delete axiosInstance.defaults.headers.common.Authorization;
  window.location.href = "/";
};

export default axiosInstance;
