import { ArrowBack, Chat, Note, StickyNote2 } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppDrawer from "../../Layout/AppDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { findDuration, FormatArray, splitDateTime } from "../../Utils/utils";
import { getData } from "../../API/apiService";
import { useAuth } from "../../customHooks/AuthContext";
import CachedIcon from "@mui/icons-material/Cached";
import SessionFeedback from "./SessionFeedback";

interface DataItem {
  [key: string]: string[];
}

export const sessionReportTitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#fff6d1",
  width: "100%",
  padding: "0.5em",
};

const SessionReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { adminPractitionerId, role } = useAuth();
  const { patientDetails, sessionid } = location.state || {};
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const autoRefreshTiming = 60000; // 60000 milliseconds = 1 minute
  const [duration, setDuration] = useState<string>("");
  const sessionTranscriptintervalIdRef = useRef<number | null>(null);
  const sessionSummaryintervalIdRef = useRef<number | null>(null);
  const sessionAIDADignosisintervalIdRef = useRef<number | null>(null);
  const sessionTreatmentPlanintervalIdRef = useRef<number | null>(null);
  const [sessionDate, setSessionDate] = useState("-");
  const [sesssionTime, setSessionTime] = useState("-");

  // Session Transcript
  const [sessionTranscriptList, setSessionTranscriptList] = useState<any[]>([]);
  const [sessionTranscriptLoading] = useState<boolean>(false);
  const [sessionTranscriptisExpanded, setSessionTranscriptisExpanded] =
    useState(false);
  // const [feedbackSubmitDisabled, setFeedbackSubmitDisabled] = useState(false);

  // Session Summary
  const [sessionSummaryList, setSessionSummaryList] = useState<any[]>([]);
  const [sessionSummaryLoading] = useState<boolean>(false);
  const [sessionSummaryIsExpanded, setsessionSummaryIsExpanded] =
    useState(false);

  // Diagnosis
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);
  const [diagnosisLoading] = useState<boolean>(false);
  const [diagnosisIsExpanded, setDiagnosisIsExpanded] = useState(false);

  // Treatment plan
  const [treatmentPlanList, setTreatmentPlanList] = useState<any[]>([]);
  const [treatmentLoading] = useState<boolean>(false);
  const [treatmentIsExpanded, setTreatmentPlanIsExpanded] = useState(false);

  const [currentExpandedReport, setCurrentExpandedReport] = useState<
    null | number
  >(null);

  let [identInfo, setIdentInfo] = useState<any>({
    visit: "NA",
    education: "NA",
    employment: "NA",
  });
  let [patientInfo, setPatientInfo] = useState<any>({
    complaint: "NA",
    firearm: "NA",
    legal_problem: "NA",
    past_psychiatric_history: "NA",
    violence: "NA",
    gender_issue: "NA",
    child_trauma: "NA",
    relationship: "NA",
    friends: "NA",
    previous_medical_history: "NA",
    liver_problem: "NA",
    heart_problem: "NA",
    vitamin_deficiency: "NA",
    infection: "NA",
    drug_allergy: "NA",
    medicaiton: "NA",
    mental_health: "NA",
    other_mental_health: "NA",
    cognitive_history: "NA",
    cognitive_functioning: "NA",
    suicide_attempt: "NA",
    suicide_ideation: "NA",
    safety: "NA",
  });

  let [substanceInfo, setSubstanceInfo] = useState<any>({
    substance: "NA",
    secondary_substances: "NA",
    frequency: "NA",
    administration: "NA",
    substance_start: "NA",
    chronic_pain: "NA",
    cultural_belief: "NA",
    motivation: "NA",
    readiness: "NA",
    coping_strategy: "NA",
    past_coping_strategy: "NA",
    withdrawal: "NA",
    relapse: "NA",
    overdose: "NA",
  });
  let [diagnosInfo, setDiagnosInfo] = useState<any>({
    framework: "NA",
    diagnosis: "NA",
    secondary_diagnosis: "NA",
    lab_work: "NA",
  });
  let [treatmentInfo, setTreatmentInfo] = useState<any>({
    treatment: "NA",
    medicaiton: "NA",
    pregnancy: "NA",
    follow_up: "NA",
  });
  let [aidaTouchInfo, setAidaTouchInfo] = useState<any>({
    vocabulary: "NA",
  });

  let [diagnoDetailInfo, setDiagnoDetailInfo] = useState<any>({
    disorder: "NA",
    code: "NA",
    severity: "NA",
  });

  let [priDiagnoRefInfo, setPriDiagnoRefInfo] = useState<any>({
    "1_dsm_control_usage": "NA",
    "2_dsm_control_desire": "NA",
    "3_dsm_control_commitment": "NA",
    "4_dsm_control_arduous": "NA",
    "5_dsm_social_failure": "NA",
    "6_dsm_social_persistence": "NA",
    "7_dsm_social_decline": "NA",
    "8_dsm_risk_hazard": "NA",
    "9_dsm_risk_tenacity": "NA",
    "10_dsm_tolerance": "NA",
    "10_a_dsm_tolerance_dosage_increase": "NA",
    "10_b_dsm_tolerance_tolerance": "NA",
    "10_c_dsm_tolerance_symptoms": "NA",
    "11_dsm_withdrawal": "NA",
    "11_a_dsm_withdrawal_relief": "NA",
    "11_b_dsm_withdrawal_symptoms": "NA",
  });

  let [treatmentDetailInfo, setTreatmentDetailInfo] = useState<any>({
    psychotherapy: "NA",
    substance_cessation: "NA",
    supportive_measures: "NA",
    pharmacotherapy: "NA",
    indication: "NA",
    fda_pregnancy_categories: "NA",
    breastfeeding_warnings: "NA",
    contraindications: "NA",
    age_warnings: "NA",
  });

  const handleViewMore = (menu: string) => {
    if (menu === "session_transcript") {
      setSessionTranscriptisExpanded((prev) => !prev);
      setCurrentExpandedReport(1);
    } else if (menu === "session_summary") {
      setsessionSummaryIsExpanded((prev) => !prev);
      setCurrentExpandedReport(2);
    } else if (menu === "diagosis") {
      setDiagnosisIsExpanded((prev) => !prev);
      setCurrentExpandedReport(3);
    } else if (menu === "treatment_plan") {
      setTreatmentPlanIsExpanded((prev) => !prev);
      setCurrentExpandedReport(4);
    }
  };

  const feedbackExpanded = (reportid: number) => {
    if (reportid === 1) {
      setSessionTranscriptisExpanded((prev) => !prev);
    } else if (reportid === 2) {
      setsessionSummaryIsExpanded((prev) => !prev);
    } else if (reportid === 3) {
      setDiagnosisIsExpanded((prev) => !prev);
    } else if (reportid === 4) {
      setTreatmentPlanIsExpanded((prev) => !prev);
    }
  };

  useEffect(() => {
    getSessionDetails();
    fetchSessionTranscript();
    fetchSessionSummary();
    fetchSessionAIDATreatmentPlan();
    fetchSessionAIDADignosis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sessionTranscriptAttemptCount = 0; // Initialize attempt counter
    let sessionSummaryAttemptCount = 0;
    let sessionAIDADignosisAttemptCount = 0;
    let sessionTreatmentAttemptCount = 0;

    sessionTranscriptintervalIdRef.current = window.setInterval(async () => {
      sessionTranscriptAttemptCount++;

      const apiResponse = await fetchSessionTranscript();

      if (apiResponse || sessionTranscriptAttemptCount >= 10) {
        clearInterval(sessionTranscriptintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionTranscriptintervalIdRef.current = null; // Reset intervalRef
      }
    }, autoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionSummaryintervalIdRef.current = window.setInterval(async () => {
      sessionSummaryAttemptCount++;

      const apiResponse = await fetchSessionSummary();

      if (apiResponse || sessionSummaryAttemptCount >= 10) {
        clearInterval(sessionSummaryintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionSummaryintervalIdRef.current = null; // Reset intervalRef
      }
    }, autoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionAIDADignosisintervalIdRef.current = window.setInterval(async () => {
      sessionAIDADignosisAttemptCount++;

      const apiResponse = await fetchSessionAIDADignosis();

      if (apiResponse || sessionAIDADignosisAttemptCount >= 10) {
        clearInterval(sessionAIDADignosisintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionAIDADignosisintervalIdRef.current = null; // Reset intervalRef
      }
    }, autoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionTreatmentPlanintervalIdRef.current = window.setInterval(async () => {
      sessionTreatmentAttemptCount++;

      const apiResponse = await fetchSessionAIDATreatmentPlan();

      if (apiResponse || sessionTreatmentAttemptCount >= 10) {
        clearInterval(sessionTreatmentPlanintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionTreatmentPlanintervalIdRef.current = null; // Reset intervalRef
      }
    }, autoRefreshTiming); // 60000 milliseconds = 1 minute

    // Cleanup on unmount
    return () => {
      if (sessionTranscriptintervalIdRef.current !== null) {
        clearInterval(sessionTranscriptintervalIdRef.current);
      }

      if (sessionSummaryintervalIdRef.current !== null) {
        clearInterval(sessionSummaryintervalIdRef.current);
      }

      if (sessionAIDADignosisintervalIdRef.current !== null) {
        clearInterval(sessionAIDADignosisintervalIdRef.current);
      }

      if (sessionTreatmentPlanintervalIdRef.current !== null) {
        clearInterval(sessionTreatmentPlanintervalIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSessionTranscript = async () => {
    try {
      
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/transcriptions/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/transcriptions/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };
  // Function to process the API response
  const processSessionTranscriptAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const mergedAudioSegments = APIResponse.flatMap(
        (item: { results: { audio_segments: any } }) =>
          item.results.audio_segments
      );
      if (mergedAudioSegments.length) {
        setSessionTranscriptList(mergedAudioSegments);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionTranscriptList([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const getSessionDetails = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/session/${adminPractitionerId}/${sessionid}`
        );
        if (APIResponse) {
          const result = splitDateTime(APIResponse.audio_created_at);
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      } else {
        const APIResponse = await getData(
          `/session/${practionerDetails.practionerInfo.id}/${sessionid}`
        );
        if (APIResponse) {
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          const result = splitDateTime(APIResponse.audio_created_at);
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
    }
  };

  const fetchSessionSummary = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/summaries/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/summaries/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionSummaryAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setSessionSummaryList(sessionSummary);
        setSessionSummaryData(sessionSummary);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionSummaryList([APIResponse]);
      setSessionSummaryData([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionAIDADignosis = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/diagnosis/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/diagnosis/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDADignosisAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setDiagnosisList(sessionSummary);
        setDiagnosisData(sessionSummary);
        return true; // API response is not empty
      } else {
        return false; // API response is not empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setDiagnosisList([APIResponse]);
      setDiagnosisData([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionAIDATreatmentPlan = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/treatment/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/treatment/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDATreatmenPlantAPIResponse = (
    APIResponse: any
  ): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const isNoTreatment = checkNoTreatement(APIResponse);
      const treatmentPlan = transformData(APIResponse);
      if (isNoTreatment) {
        setTreatmentPlanList(treatmentPlan);
        setTreatmentPlanData(treatmentPlanList);
        return true; // No Need call Chunking API
      }
      if (treatmentPlan.length) {
        setTreatmentPlanList(treatmentPlan);
        setTreatmentPlanData(treatmentPlanList);
        return true; // API response is not empty
      } else {
        return false;
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setTreatmentPlanList([APIResponse]);
      setTreatmentPlanData(treatmentPlanList);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const transformData = (input: DataItem[]): DataItem[] => {
    return input.reduce((acc: DataItem[], obj: DataItem) => {
      Object.keys(obj).forEach((key: string) => {
        acc.push({ [key]: obj[key] });
      });
      return acc;
    }, []);
  };

  const setSessionSummaryData = (sessionSummary: any) => {
    let identiObj: any = {};
    let patientObj: any = {};
    let substanceObj: any = {};
    let diagnosObj: any = {};
    let treatmentObj: any = {};
    let aidaTouchObj: any = {};
    let priDiagnoObj: any = {};
    sessionSummary.map((sessionData: any) => {
      Object.entries(sessionData).map(([key, value]) => {
        if (key === "visit") {
          identiObj["visit"] = value;
        }
        if (key === "education") {
          identiObj["education"] = value;
        }
        if (key === "employment") {
          identiObj["employment"] = value;
        }
        if (key === "complaint") {
          patientObj["complaint"] = value;
        }
        if (key === "firearm") {
          patientObj["firearm"] = value;
        }
        if (key === "legal_problem") {
          patientObj["legal_problem"] = value;
        }
        if (key === "past_psychiatric_history") {
          patientObj["past_psychiatric_history"] = value;
        }
        if (key === "violence") {
          patientObj["violence"] = value;
        }
        if (key === "gender_issue") {
          patientObj["gender_issue"] = value;
        }
        if (key === "child_trauma") {
          patientObj["child_trauma"] = value;
        }
        if (key === "relationship") {
          patientObj["relationship"] = value;
        }
        if (key === "friends") {
          patientObj["friends"] = value;
        }
        if (key === "previous_medical_history") {
          patientObj["previous_medical_history"] = value;
        }
        if (key === "liver_problem") {
          patientObj["liver_problem"] = value;
        }
        if (key === "heart_problem") {
          patientObj["heart_problem"] = value;
        }
        if (key === "vitamin_deficiency") {
          patientObj["vitamin_deficiency"] = value;
        }
        if (key === "infection") {
          patientObj["infection"] = value;
        }
        if (key === "drug_allergy") {
          patientObj["drug_allergy"] = value;
        }
        if (key === "medicaiton") {
          patientObj["medicaiton"] = value;
        }
        if (key === "mental_health") {
          patientObj["mental_health"] = value;
        }
        if (key === "other_mental_health") {
          patientObj["other_mental_health"] = value;
        }
        if (key === "cognitive_history") {
          patientObj["cognitive_history"] = value;
        }
        if (key === "cognitive_functioning") {
          patientObj["cognitive_functioning"] = value;
        }
        if (key === "suicide_attempt") {
          patientObj["suicide_attempt"] = value;
        }
        if (key === "suicide_ideation") {
          patientObj["suicide_ideation"] = value;
        }
        if (key === "safety") {
          patientObj["safety"] = value;
        }

        if (key === "substance") {
          substanceObj["substance"] = value;
        }
        if (key === "secondary_substances") {
          substanceObj["secondary_substances"] = value;
        }
        if (key === "frequency") {
          substanceObj["frequency"] = value;
        }
        if (key === "administration") {
          substanceObj["administration"] = value;
        }
        if (key === "substance_start") {
          substanceObj["substance_start"] = value;
        }
        if (key === "chronic_pain") {
          substanceObj["chronic_pain"] = value;
        }
        if (key === "cultural_belief") {
          substanceObj["cultural_belief"] = value;
        }
        if (key === "motivation") {
          substanceObj["motivation"] = value;
        }
        if (key === "readiness") {
          substanceObj["readiness"] = value;
        }
        if (key === "coping_strategy") {
          substanceObj["coping_strategy"] = value;
        }
        if (key === "past_coping_strategy") {
          substanceObj["past_coping_strategy"] = value;
        }
        if (key === "withdrawal") {
          substanceObj["withdrawal"] = value;
        }
        if (key === "relapse") {
          substanceObj["relapse"] = value;
        }
        if (key === "overdose") {
          substanceObj["overdose"] = value;
        }

        if (key === "framework") {
          diagnosObj["framework"] = value;
        }
        if (key === "diagnosis") {
          diagnosObj["diagnosis"] = value;
        }
        if (key === "secondary_diagnosis") {
          diagnosObj["secondary_diagnosis"] = value;
        }
        if (key === "lab_work") {
          diagnosObj["lab_work"] = value;
        }

        if (key === "treatment") {
          treatmentObj["treatment"] = value;
        }
        if (key === "medicaiton") {
          treatmentObj["medicaiton"] = value;
        }
        if (key === "pregnancy") {
          treatmentObj["pregnancy"] = value;
        }
        if (key === "follow_up") {
          treatmentObj["follow_up"] = value;
        }

        if (key === "vocabulary") {
          aidaTouchObj["vocabulary"] = value;
        }

        if (key === "1_dsm_control_usage") {
          priDiagnoObj["1_dsm_control_usage"] = value;
        }
        if (key === "2_dsm_control_desire") {
          priDiagnoObj["2_dsm_control_desire"] = value;
        }
        if (key === "3_dsm_control_commitment") {
          priDiagnoObj["3_dsm_control_commitment"] = value;
        }
        if (key === "4_dsm_control_arduous") {
          priDiagnoObj["4_dsm_control_arduous"] = value;
        }
        if (key === "5_dsm_social_failure") {
          priDiagnoObj["5_dsm_social_failure"] = value;
        }
        if (key === "6_dsm_social_persistence") {
          priDiagnoObj["6_dsm_social_persistence"] = value;
        }
        if (key === "7_dsm_social_decline") {
          priDiagnoObj["7_dsm_social_decline"] = value;
        }
        if (key === "8_dsm_risk_hazard") {
          priDiagnoObj["8_dsm_risk_hazard"] = value;
        }
        if (key === "9_dsm_risk_tenacity") {
          priDiagnoObj["9_dsm_risk_tenacity"] = value;
        }
        if (key === "10_dsm_tolerance") {
          priDiagnoObj["10_dsm_tolerance"] = value;
        }
        if (key === "10_a_dsm_tolerance_dosage_increase") {
          priDiagnoObj["10_a_dsm_tolerance_dosage_increase"] = value;
        }
        if (key === "10_b_dsm_tolerance_tolerance") {
          priDiagnoObj["10_b_dsm_tolerance_tolerance"] = value;
        }
        if (key === "10_c_dsm_tolerance_symptoms") {
          priDiagnoObj["10_c_dsm_tolerance_symptoms"] = value;
        }
        if (key === "11_dsm_withdrawal") {
          priDiagnoObj["11_dsm_withdrawal"] = value;
        }
        if (key === "11_a_dsm_withdrawal_relief") {
          priDiagnoObj["11_a_dsm_withdrawal_relief"] = value;
        }
        if (key === "11_b_dsm_withdrawal_symptoms") {
          priDiagnoObj["11_b_dsm_withdrawal_symptoms"] = value;
        }
        return sessionData;
      });
      return sessionData;
    });
    identInfo = identiObj;
    patientInfo = patientObj;
    substanceInfo = substanceObj;
    treatmentInfo = treatmentObj;
    diagnosInfo = diagnosObj;
    aidaTouchInfo = aidaTouchObj;
    priDiagnoRefInfo = priDiagnoObj;
    setIdentInfo(identiObj);
    setPatientInfo(patientObj);
    setSubstanceInfo(substanceObj);
    setDiagnosInfo(diagnosObj);
    setTreatmentInfo(treatmentObj);
    setAidaTouchInfo(aidaTouchObj);
    setPriDiagnoRefInfo(priDiagnoObj);
  };

  const setDiagnosisData = (diagnosisData: any) => {
    let diagnoDetailObj: any = {};
    diagnosisData.map((diagnosisData: any) => {
      Object.entries(diagnosisData).map(([key, value]: any) => {
        if (key === "sud") {
          let disorder: any = [];
          let code: any = [];
          let severity: any = [];
          value["diagnosis"].map((diagno: any) => {
            disorder = [...disorder, diagno.disorder];
            code = [...code, diagno.code];
            severity = [...severity, diagno.severity];
            return diagno;
          });
          diagnoDetailObj.disorder = disorder;
          diagnoDetailObj.code = code;
          diagnoDetailObj.severity = severity;
          diagnoDetailInfo = diagnoDetailObj;
          setDiagnoDetailInfo(diagnoDetailObj);
        }
        return diagnosisData;
      });
      return diagnosisData;
    });
  };

  const setTreatmentPlanData = (treatmentPlan: any) => {
    let treatmentDetailObj: any = {};

    let psychotherapy: any = [];
    let pharmacotherapy: any = [];
    let substance_cessation: any = [];
    let indication: any = [];
    let fda_pregnancy_categories: any = [];
    let supportive_measures: any = [];
    let breastfeeding_warnings: any = [];
    let contraindications: any = [];
    let age_warnings: any = [];

    Object.values(treatmentPlan).map((obj: any) => {
      Object.entries(obj).map(([key, value]: any) => {
        // psychotherapy = [...psychotherapy, value.psychotherapy];
        // substance_cessation = [
        //   ...substance_cessation,
        //   value.substance_cessation,
        // ];
        // supportive_measures = [
        //   ...supportive_measures,
        //   value.supportive_measures,
        // ];
        if (value.psychotherapy !== "N/A") {
          psychotherapy = [...psychotherapy, value.psychotherapy];
        }
        if (value.substance_cessation !== "N/A") {
          substance_cessation = [
            ...substance_cessation,
            value.substance_cessation,
          ];
        }
        if (value.supportive_measures !== "N/A") {
          supportive_measures = [
            ...supportive_measures,
            value.supportive_measures,
          ];
        }
        if (
          value?.medication &&
          value?.medication &&
          value?.medication.length > 0
        ) {
          value?.medication.map((medica: any) => {
            pharmacotherapy = [...pharmacotherapy, medica.pharmacotherapy];
            indication = [...indication, medica.indication];
            fda_pregnancy_categories = [
              ...fda_pregnancy_categories,
              medica.fda_pregnancy_categories,
            ];
            breastfeeding_warnings = [
              ...breastfeeding_warnings,
              medica.breastfeeding_warnings,
            ];
            contraindications = [
              ...contraindications,
              medica.contraindications,
            ];
            age_warnings = [...age_warnings, medica.age_warnings];
            return medica;
          });
        }
        return value;
      });
      return obj;
    });
    treatmentDetailObj.psychotherapy = psychotherapy;
    treatmentDetailObj.substance_cessation = substance_cessation;
    treatmentDetailObj.supportive_measures = supportive_measures;
    treatmentDetailObj.supportive_measures = psychotherapy;
    treatmentDetailObj.pharmacotherapy = pharmacotherapy;
    treatmentDetailObj.indication = indication;
    treatmentDetailObj.fda_pregnancy_categories = fda_pregnancy_categories;
    treatmentDetailObj.breastfeeding_warnings = breastfeeding_warnings;
    treatmentDetailObj.contraindications = contraindications;
    treatmentDetailObj.age_warnings = age_warnings;
    treatmentDetailInfo = treatmentDetailObj;
    setTreatmentDetailInfo(treatmentDetailObj);
  };

  const checkNoTreatement = (response: any) => {
    const expectedResponse = [
      {
        Treatment:
          "There is currently no treatment plan available for this session.",
      },
    ];
    const isSameResponse =
      JSON.stringify(response) === JSON.stringify(expectedResponse);

    return isSameResponse;
  };

  const downloadTxtFile = (menu: string) => {
    // Step 1: Create the content for the file
    // Create text content dynamically using map
    let content = "";
    let fileName = "sample.txt";
    if (menu === "session_transcript") {
      content = sessionTranscriptList
        .map((item, i) => `${item.speaker_label}: ${item.transcript}`)
        .join("\n"); // Join each transcript item with a new line

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} SessionTranscript.txt`;
    } else if (menu === "session_treatment_plan") {
      content =
        document.getElementById("sessionTreatmentPlanDiv")?.innerText ||
        "There is No Session Treatement found.";

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDATreatmentPlan.txt`;
    } else if (menu === "session_summary") {
      content =
        document.getElementById("sessionSummaryDiv")?.innerText ||
        "There is No Session Summary found.";
      fileName = `${patientDetails.first_name}-${patientDetails.last_name} Session-Notes.txt`;
    } else if (menu === "aida_dignosis") {
      content =
        document.getElementById("sessionDignosisDiv")?.innerText ||
        "There is No Session Dignosis found.";

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDA-Diagnosis.txt`;
    }

    // Step 2: Create a Blob object with the content as plain text
    const blob = new Blob([content], { type: "text/plain" });

    // Step 3: Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Step 4: Create an anchor element and simulate a click
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName; // Name of the file with .txt extension
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl); // Revoke the object URL after download
  };

  const handleBack = () => {
    if (sessionSummaryIsExpanded) {
      setsessionSummaryIsExpanded(false);
    } else if (diagnosisIsExpanded) {
      setDiagnosisIsExpanded(false);
    } else if (treatmentIsExpanded) {
      setTreatmentPlanIsExpanded(false);
    } else {
      setSessionTranscriptisExpanded(false);
    }
    if (
      !sessionSummaryIsExpanded &&
      !diagnosisIsExpanded &&
      !treatmentIsExpanded &&
      !sessionTranscriptisExpanded
    ) {
      navigate("/patient-details", {
        state: { patientDetails: patientDetails },
      });
    }
  };

  return (
    <AppDrawer>
      <Paper
        variant="outlined"
        component="fieldset"
        sx={{
          backgroundColor: "#ffcc00",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 3, fontSize: "medium" }}
            >
              {patientDetails?.first_name ? patientDetails.first_name : "-"}{" "}
              {patientDetails?.last_name ? patientDetails.last_name : "-"}
              <Divider
                sx={{ backgroundColor: "#ffcc00", borderBottomWidth: 2 }}
              ></Divider>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              startIcon={<ArrowBack />}
              style={{
                backgroundColor: "#ffcc00",
                borderRadius: "05px",
                border: "1px solid black",
                color: "black",
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Box height={10}></Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ fontWeight: "bold", fontSize: "small" }}
        >
          <Grid item xs={3}>
            Initial Consultation Date
          </Grid>
          <Grid item xs={3}>
            Appointment Time
          </Grid>

          <Grid item xs={2}>
            Appointment Date
          </Grid>
          <Grid item xs={2}>
            Duration
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sesssionTime}
          </Grid>

          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {duration} Min
          </Grid>
        </Grid>
      </Paper>
      <Box height={10}></Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        AIDA Reports
      </Typography>
      <Box height={10}></Box>

      <Grid container spacing={2}>
        {/* Session Summary */}
        {!sessionTranscriptisExpanded &&
          !diagnosisIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={sessionSummaryIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: sessionSummaryIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      <Note sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Session Summary
                    </Typography>
                   
                      <div>
                        <CachedIcon
                          onClick={fetchSessionSummary}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                   
                  </div>
                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionSummaryDiv"
                    sx={{
                      flex: 1,
                      minHeight: "150px",
                      maxHeight: sessionSummaryIsExpanded ? "300px" : "50px",
                      overflowY: sessionSummaryIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease",
                      overflowX: "hidden",
                    }}
                  >
                    {!sessionSummaryLoading &&
                      sessionSummaryList.length > 0 && (
                        <span
                          className="session_report_title"
                          style={sessionReportTitle}
                        >
                          Identifying Information
                        </span>
                      )}
                    {!sessionSummaryLoading &&
                      sessionSummaryList.length > 0 && (
                        <>
                          <ul>
                            <FormatArray
                              subIndex={"visit"}
                              keyName="Visit"
                              value={identInfo.visit}
                            />
                            <FormatArray
                              subIndex={"education"}
                              keyName="Education"
                              value={identInfo.education}
                            />
                            <FormatArray
                              subIndex={"employment"}
                              keyName="Employment"
                              value={identInfo.employment}
                            />
                          </ul>
                          <span style={sessionReportTitle}>
                            Patient Background
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"complaint"}
                              keyName="Chief Complaint"
                              value={patientInfo.complaint}
                            />
                            <FormatArray
                              subIndex={"firearm"}
                              keyName="Access To Firearms"
                              value={patientInfo.firearm}
                            />
                            <FormatArray
                              subIndex={"legal_problem"}
                              keyName="Legal Problems"
                              value={patientInfo.legal_problem}
                            />
                            <li>
                              <strong>Past Psychiatric History</strong>
                              <ul>
                                <FormatArray
                                  subIndex={"violence"}
                                  keyName="Previous Psychiatric Problems"
                                  value={patientInfo.violence}
                                />
                                <FormatArray
                                  subIndex={"gender_issue"}
                                  keyName="Gender Issue"
                                  value={patientInfo.gender_issue}
                                />
                                <FormatArray
                                  subIndex={"mental_health"}
                                  keyName="Mental Health Status"
                                  value={patientInfo.mental_health}
                                />
                              </ul>
                            </li>
                            <li>
                              <strong>Family History</strong>
                              <ul>
                                <FormatArray
                                  subIndex={"child_trauma"}
                                  keyName="History of Child Trauma"
                                  value={patientInfo.child_trauma}
                                />
                                <FormatArray
                                  subIndex={"relationship"}
                                  keyName="Relationship With Family"
                                  value={patientInfo.relationship}
                                />
                                <FormatArray
                                  subIndex={"friends"}
                                  keyName="Relationships With Friends and Colleagues"
                                  value={patientInfo.friends}
                                />
                              </ul>
                            </li>
                            <li>
                              <strong>Medical History</strong>
                              <ul>
                                <FormatArray
                                  subIndex={"previous_medical_history"}
                                  keyName="Previous Surgeries And Hospitalization"
                                  value={patientInfo.previous_medical_history}
                                />
                                <FormatArray
                                  subIndex={"liver_problem"}
                                  keyName="Liver Problem"
                                  value={patientInfo.liver_problem}
                                />
                                <FormatArray
                                  subIndex={"heart_problem"}
                                  keyName="Heart Problems"
                                  value={patientInfo.heart_problem}
                                />
                                <FormatArray
                                  subIndex={"vitamin_deficiency"}
                                  keyName="Vitamin Deficiancy"
                                  value={patientInfo.vitamin_deficiency}
                                />
                                <FormatArray
                                  subIndex={"infection"}
                                  keyName="Infections"
                                  value={patientInfo.infection}
                                />
                              </ul>
                            </li>
                            <FormatArray
                              subIndex={"drug_allergy"}
                              keyName="Allergies"
                              value={patientInfo.drug_allergy}
                            />
                            <FormatArray
                              subIndex={"medicaiton"}
                              keyName="Current Medications"
                              value={patientInfo.medicaiton}
                            />
                            <li>
                              <strong>Mental Health Status</strong>
                              <ul>
                                <FormatArray
                                  subIndex={"mental_health"}
                                  keyName="Mental Health Status"
                                  value={patientInfo.mental_health}
                                />
                                <FormatArray
                                  subIndex={"other_mental_health"}
                                  keyName="Other Mental Health Issues"
                                  value={patientInfo.other_mental_health}
                                />
                                <FormatArray
                                  subIndex={"cognitive_history"}
                                  keyName="Cognitive History"
                                  value={patientInfo.cognitive_history}
                                />
                                <FormatArray
                                  subIndex={"cognitive_functioning"}
                                  keyName="Cognitive Functioning"
                                  value={patientInfo.cognitive_functioning}
                                />
                              </ul>
                            </li>
                            <li>
                              <strong>Suicide</strong>
                              <ul>
                                <FormatArray
                                  subIndex={"suicide_attempt"}
                                  keyName="Suicide Attempt"
                                  value={patientInfo.suicide_attempt}
                                />
                                <FormatArray
                                  subIndex={"suicide_ideation"}
                                  keyName="Suicide Ideation"
                                  value={patientInfo.suicide_ideation}
                                />
                              </ul>
                            </li>
                            <FormatArray
                              subIndex={"safety"}
                              keyName="Safety"
                              value={patientInfo.safety}
                            />
                          </ul>
                          <span style={sessionReportTitle}>Substance use</span>
                          <ul>
                            <FormatArray
                              subIndex={"substance"}
                              keyName="Main Substance"
                              value={substanceInfo.substance}
                            />
                            <FormatArray
                              subIndex={"secondary_substances"}
                              keyName="Other Substances"
                              value={substanceInfo.secondary_substances}
                            />
                            <FormatArray
                              subIndex={"frequency"}
                              keyName="Substance Use Frequency"
                              value={substanceInfo.frequency}
                            />
                            <FormatArray
                              subIndex={"administration"}
                              keyName="Substance Administration Method"
                              value={substanceInfo.administration}
                            />
                            <FormatArray
                              subIndex={"substance_start"}
                              keyName="Substance Use Start"
                              value={substanceInfo.substance_start}
                            />
                            <FormatArray
                              subIndex={"chronic_pain"}
                              keyName="Chronic Pain History"
                              value={substanceInfo.chronic_pain}
                            />
                            <FormatArray
                              subIndex={"cultural_belief"}
                              keyName="Cultural Belief"
                              value={substanceInfo.cultural_belief}
                            />
                            <FormatArray
                              subIndex={"motivation"}
                              keyName="Motivation To Quit"
                              value={substanceInfo.motivation}
                            />
                            <FormatArray
                              subIndex={"readiness"}
                              keyName="Treatment Readiness"
                              value={substanceInfo.readiness}
                            />
                            <FormatArray
                              subIndex={"coping_strategy"}
                              keyName="Current Coping Strategies"
                              value={substanceInfo.coping_strategy}
                            />
                            <FormatArray
                              subIndex={"past_coping_strategy"}
                              keyName="Coping History"
                              value={substanceInfo.past_coping_strategy}
                            />
                            <FormatArray
                              subIndex={"withdrawal"}
                              keyName="Withdrawal Symptom"
                              value={substanceInfo.withdrawal}
                            />
                            <FormatArray
                              subIndex={"relapse"}
                              keyName="History Of Relapse"
                              value={substanceInfo.relapse}
                            />
                            <FormatArray
                              subIndex={"overdose"}
                              keyName="History Of Overdose"
                              value={substanceInfo.overdose}
                            />
                          </ul>
                          <span style={sessionReportTitle}>Diagnosis</span>
                          <ul>
                            <FormatArray
                              subIndex={"framework"}
                              keyName="Screening Tools & Test"
                              value={diagnosInfo.framework}
                            />
                            <FormatArray
                              subIndex={"diagnosis"}
                              keyName="Principal Diagnosis"
                              value={diagnosInfo.diagnosis}
                            />
                            <FormatArray
                              subIndex={"secondary_diagnosis"}
                              keyName="Secondary Diagnosis"
                              value={diagnosInfo.secondary_diagnosis}
                            />
                            <FormatArray
                              subIndex={"lab_work"}
                              keyName="Lab Work"
                              value={diagnosInfo.lab_work}
                            />
                          </ul>
                          <span style={sessionReportTitle}>Treatment</span>
                          <ul>
                            <FormatArray
                              subIndex={"treatment"}
                              keyName="Treatment"
                              value={treatmentInfo.treatment}
                            />
                            <FormatArray
                              subIndex={"medicaiton"}
                              keyName="Current Medications"
                              value={treatmentInfo.medicaiton}
                            />
                            <FormatArray
                              subIndex={"pregnancy"}
                              keyName="Pregnancy"
                              value={treatmentInfo.pregnancy}
                            />
                            <FormatArray
                              subIndex={"follow_up"}
                              keyName="Follow up meeting"
                              value={treatmentInfo.follow_up}
                            />
                          </ul>
                          <span style={sessionReportTitle}>AIDA’s Touch</span>
                          <ul>
                            <FormatArray
                              subIndex={"vocabulary"}
                              keyName="Vocabulary"
                              value={aidaTouchInfo.vocabulary}
                            />
                          </ul>
                        </>
                      )}
                    {!sessionSummaryLoading && !sessionSummaryList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}
                    {sessionSummaryLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!sessionSummaryIsExpanded &&
                  !sessionSummaryLoading &&
                  sessionSummaryList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("session_summary")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {sessionSummaryIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("session_summary")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {sessionSummaryIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* AIDA Diagnosis Recommendation */}
        {!sessionTranscriptisExpanded &&
          !sessionSummaryIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={diagnosisIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: diagnosisIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px", // Adjust the border radius as needed
                      display: "flex",
                      gap: "10px", // Adjust the gap between icon and text as needed
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      <StickyNote2 sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      AIDA Diagnosis Recommendation
                    </Typography>
                    
                      <div>
                        <CachedIcon
                          onClick={fetchSessionAIDADignosis}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                   
                  </div>

                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionDignosisDiv"
                    sx={{
                      flex: 1, // Makes it fill the available space
                      minHeight: "150px", // Set minimum height for the transcript
                      maxHeight: diagnosisIsExpanded ? "300px" : "150px", // Expand/Collapse max height
                      overflowY: diagnosisIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                      overflowX: "hidden",
                    }}
                  >
                    {!diagnosisLoading && diagnosisList.length > 0 && (
                      <>
                        <span style={sessionReportTitle}>
                          Diagnosis Summary
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"disorder"}
                            keyName="Principal Diagnosis"
                            value={
                              diagnoDetailInfo.disorder.length && diagnoDetailInfo.severity.length
                                ? `${diagnoDetailInfo.disorder},${diagnoDetailInfo.severity}`
                                : "N-A"
                            }
                          />
                          <FormatArray
                            subIndex={"code"}
                            keyName="ICD-10 CM code"
                            value={diagnoDetailInfo.code}
                          />
                        </ul>
                        <span style={sessionReportTitle}>Diagnosis Detail</span>
                        <ul>
                          <FormatArray
                            subIndex={"disorder"}
                            keyName="Principal Diagnosis"
                            value={
                              diagnoDetailInfo.disorder.length && diagnoDetailInfo.severity.length
                                ? `${diagnoDetailInfo.disorder},${diagnoDetailInfo.severity}`
                                : "N-A"
                            }
                          />
                          <FormatArray
                            subIndex={"code"}
                            keyName="ICD-10 CM code"
                            value={diagnoDetailInfo.code}
                          />
                          <FormatArray
                            subIndex={"substance"}
                            keyName="Main Substance"
                            value={substanceInfo.substance}
                          />
                          <FormatArray
                            subIndex={"secondary_diagnosis"}
                            keyName="Other Substances"
                            value={substanceInfo.secondary_diagnosis}
                          />
                          <FormatArray
                            subIndex={"severity"}
                            keyName="Principal Diagnosis Severity"
                            value={diagnoDetailInfo.severity}
                          />
                          <FormatArray
                            subIndex={"substance"}
                            keyName="Diagnosis Frameworks"
                            value="ICD-10 & DSM 5 substance use disorder."
                          />
                        </ul>

                        <span style={sessionReportTitle}>
                          Primary Diagnosis References
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"1_dsm_control_usage"}
                            keyName="Impaired Control - Usage"
                            value={priDiagnoRefInfo["1_dsm_control_usage"]}
                          />
                          <FormatArray
                            subIndex={"2_dsm_control_desire"}
                            keyName="Impaired Control - Desire To Cut Down"
                            value={priDiagnoRefInfo["2_dsm_control_desire"]}
                          />
                          <FormatArray
                            subIndex={"3_dsm_control_commitment"}
                            keyName="Impaired Control - Time Commitment"
                            value={priDiagnoRefInfo["3_dsm_control_commitment"]}
                          />
                          <FormatArray
                            subIndex={"4_dsm_control_arduous"}
                            keyName="Impaired Control - Craving"
                            value={priDiagnoRefInfo["4_dsm_control_arduous"]}
                          />
                          <FormatArray
                            subIndex={"5_dsm_social_failure"}
                            keyName="Social Impairment - Failure"
                            value={priDiagnoRefInfo["5_dsm_social_failure"]}
                          />
                          <FormatArray
                            subIndex={"6_dsm_social_persistence"}
                            keyName="Social Impairment - Persistence"
                            value={priDiagnoRefInfo["6_dsm_social_persistence"]}
                          />
                          <FormatArray
                            subIndex={"7_dsm_social_decline"}
                            keyName="Social Impairment - Decline"
                            value={priDiagnoRefInfo["7_dsm_social_decline"]}
                          />
                          <FormatArray
                            subIndex={"8_dsm_risk_hazard"}
                            keyName="Risky Use - Hazard"
                            value={priDiagnoRefInfo["8_dsm_risk_hazard"]}
                          />
                          <FormatArray
                            subIndex={"9_dsm_risk_tenacity"}
                            keyName="Risky Use - Tenacity"
                            value={priDiagnoRefInfo["9_dsm_risk_tenacity"]}
                          />
                          <FormatArray
                            subIndex={"10_dsm_tolerance"}
                            keyName="Tolerance"
                            value={priDiagnoRefInfo["10_dsm_tolerance"]}
                          />
                          <ul>
                            <FormatArray
                              subIndex={"10_a_dsm_tolerance_dosage_increase"}
                              keyName="Tolerance - Dosage Increase"
                              value={
                                priDiagnoRefInfo[
                                  "10_a_dsm_tolerance_dosage_increase"
                                ]
                              }
                            />
                            <FormatArray
                              subIndex={"10_b_dsm_tolerance_tolerance"}
                              keyName="Tolerance - Diminished Effect"
                              value={
                                priDiagnoRefInfo["10_b_dsm_tolerance_tolerance"]
                              }
                            />
                            <FormatArray
                              subIndex={"10_c_dsm_tolerance_symptoms"}
                              keyName="Tolerance - Symptoms"
                              value={
                                priDiagnoRefInfo["10_c_dsm_tolerance_symptoms"]
                              }
                            />
                          </ul>
                          <FormatArray
                            subIndex={"11_dsm_withdrawal"}
                            keyName="Withdrawal"
                            value={priDiagnoRefInfo["11_dsm_withdrawal"]}
                          />
                          <ul>
                            <FormatArray
                              subIndex={"11_a_dsm_withdrawal_relief"}
                              keyName="Withdrawal - Relief"
                              value={
                                priDiagnoRefInfo["11_a_dsm_withdrawal_relief"]
                              }
                            />
                            <FormatArray
                              subIndex={"11_b_dsm_withdrawal_symptoms"}
                              keyName="Withdrawal - Symptoms"
                              value={
                                priDiagnoRefInfo["11_b_dsm_withdrawal_symptoms"]
                              }
                            />
                          </ul>
                        </ul>
                      </>
                    )}

                    {!diagnosisLoading && !diagnosisList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically apear on screen once ready.
                      </Typography>
                    )}
                    {diagnosisLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!diagnosisLoading &&
                  !diagnosisIsExpanded &&
                  diagnosisList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("diagosis")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {diagnosisIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("aida_dignosis")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {diagnosisIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* Session Transcript */}
        {!sessionSummaryIsExpanded &&
          !diagnosisIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={sessionTranscriptisExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: sessionTranscriptisExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00", // Keeps the background color the same on hover
                        },
                      }}
                    >
                      <Chat sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Session Transcript
                    </Typography>
                    
                      <div>
                        <CachedIcon
                          onClick={fetchSessionTranscript}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    
                  </div>

                  <Box height={10}></Box>

                  {!sessionTranscriptLoading &&
                    !sessionTranscriptList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}

                  {!sessionTranscriptLoading && (
                    <Typography
                      variant="body2"
                      component="div"
                      id="sessionTranscriptDiv"
                      sx={{
                        flex: 1, // Makes it fill the available space
                        minHeight: "150px",
                        maxHeight: sessionTranscriptisExpanded
                          ? "300px"
                          : "50px", // Corrected to "50px"
                        overflowY: sessionTranscriptisExpanded
                          ? "auto"
                          : "hidden",
                        transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                        overflowX: "hidden",
                      }}
                    >
                      {sessionTranscriptList.map((item, i) => (
                        <div key={i}>
                          <strong style={{ justifyContent: "flex-end" }}>
                            {item.speaker_label}:
                          </strong>{" "}
                          {item.transcript}
                          <br />
                        </div>
                      ))}
                    </Typography>
                  )}

                  {sessionTranscriptLoading && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ mt: 3 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>

                {!sessionTranscriptLoading &&
                  !sessionTranscriptisExpanded &&
                  sessionTranscriptList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("session_transcript")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {sessionTranscriptisExpanded
                          ? "View Less"
                          : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("session_transcript")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {sessionTranscriptisExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* AIDA Treatment Plan Recommendation */}
        {!sessionTranscriptisExpanded &&
          !sessionSummaryIsExpanded &&
          !diagnosisIsExpanded && (
            <Grid item xs={12} md={treatmentIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: treatmentIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px", // Adjust the border radius as needed
                      display: "flex",

                      gap: "10px", // Adjust the gap between icon and text as needed
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%", // Makes the button rounded
                        // border: "1px solid black",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00", // Keeps the background color the same on hover
                        },
                      }}
                    >
                      <Chat sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      AIDA Treatment Plan Recommendation
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionAIDATreatmentPlan}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionTreatmentPlanDiv"
                    sx={{
                      flex: 1, // Makes it fill the available space
                      minHeight: "150px", // Set minimum height for the transcript
                      maxHeight: treatmentIsExpanded ? "300px" : "50px", // Expand/Collapse max height
                      overflowY: treatmentIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                      overflowX: "hidden",
                    }}
                  >
                    {!diagnosisLoading && diagnosisList.length > 0 && (
                      <>
                        <span style={sessionReportTitle}>
                          Treatment plan summary
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"pharmacotherapy"}
                            keyName="Medication"
                            value={treatmentDetailInfo.pharmacotherapy}
                          />
                          <FormatArray
                            subIndex={"psychotherapy"}
                            keyName="Therapy"
                            value={treatmentDetailInfo.psychotherapy}
                          />
                        </ul>
                        <span style={sessionReportTitle}>
                          Pharmacological Suggestion
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"pharmacotherapy"}
                            keyName="Medication Name"
                            value={treatmentDetailInfo.pharmacotherapy}
                          />
                          <FormatArray
                            subIndex={"indication"}
                            keyName="Medication Indication"
                            value={treatmentDetailInfo.indication}
                          />
                          <FormatArray
                            subIndex={"substance"}
                            keyName="Pregnancy Alerts"
                            value={treatmentDetailInfo.fda_pregnancy_categories}
                          />
                          <FormatArray
                            subIndex={"secondary_diagnosis"}
                            keyName="Breastfeeding Alerts"
                            value={treatmentDetailInfo.breastfeeding_warnings}
                          />
                          <FormatArray
                            subIndex={"severity"}
                            keyName="Contraindications"
                            value={treatmentDetailInfo.contraindications}
                          />
                          <FormatArray
                            subIndex={"substance"}
                            keyName="Diagnosis Frameworks"
                            value={treatmentDetailInfo.age_warnings}
                          />
                        </ul>

                        <span style={sessionReportTitle}>
                          Non-Pharmacological
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"psychotherapy"}
                            keyName="Therapy"
                            value={treatmentDetailInfo.psychotherapy}
                          />
                          <FormatArray
                            subIndex={"supportive_measures"}
                            keyName="Supportive Measures"
                            value={treatmentDetailInfo.supportive_measures}
                          />
                          <FormatArray
                            subIndex={"substance_cessation"}
                            keyName="Substance Cessation"
                            value={treatmentDetailInfo.substance_cessation}
                          />
                        </ul>
                      </>
                    )}
                    {!treatmentLoading && !treatmentPlanList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}
                    {treatmentLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!treatmentLoading &&
                  !treatmentIsExpanded &&
                  !sessionTranscriptisExpanded &&
                  treatmentPlanList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("treatment_plan")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {treatmentIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() =>
                          downloadTxtFile("session_treatment_plan")
                        }
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {treatmentIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}
      </Grid>
    </AppDrawer>
  );
};

export default SessionReport;